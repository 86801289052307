import GET_ARTICLES from "../class/getArticles";

const [d,header,mq] = [document,document.querySelector('.rwd002-header'),window.matchMedia( "(max-width: 1023px)" ) ];

class Blocks {
  constructor() {
    this.init();
  }

  init() {
    this.headline();
    this.news('.block-news__list[data-plugin="getArticles"]');
  }

  //headline
  headline() {
    const getArticle = new GET_ARTICLES('.block-headline__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item,content) => `
      <a href="${item.href}" class="block-headline__link">
        <span class="date">${item.date}</span>
        <span class="cate">${item.category.name}</span>
        <h3 class="subject">${item.title.str_truncate(30)}</h3>
        <i class="fa-solid fa-arrow-right"></i>
      </a>`;
    getArticle.render();
  }

  news (selector) {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span><span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
        <i class="fa-solid fa-arrow-right"></i>
      </a>
    </li>
    `;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
}

const domReady = TPL => {
  let blocks = new Blocks;
};

// const domReady = TPL => false;

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}